import { createSelector } from 'reselect';


type ResponseWithMeta<T extends Record<string, unknown>> = {
  data?: T | T[];
  meta?: {
    data_map?: {
      // [k: string]: Record<string, unknown> | Record<number, Record<string, unknown>>
      [k: string]: Record<string | number, unknown>;
    };
  };
};


export const selectDenormalizedData = createSelector([
  <T extends Record<string, unknown>>(responseBody: ResponseWithMeta<T>) => responseBody,
  (responseBody, additionalMap?: { [k: string]: Record<string | number, unknown> }) => additionalMap,
], (responseBody, additionalMap) => {
  const data = responseBody.data;
  const dataMap = responseBody.meta?.data_map || additionalMap ? {
    ...responseBody.meta?.data_map,
    ...additionalMap,
  } : undefined;

  if (!data || !dataMap) return data;


  if (Array.isArray(data)) {
    return data.map(c => {
      const newDataEntry = { ...c };

      Object.entries(dataMap).forEach(([fieldName, fieldValue]) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (fieldName in newDataEntry && Object.keys(fieldValue).every(k => !isNaN(k as any))) {
          const dataFieldValue = newDataEntry[fieldName];
          if (typeof dataFieldValue === 'number') {
            newDataEntry[fieldName] = fieldValue[dataFieldValue];
          }
        }
      });

      return newDataEntry;
    });
  } else if (!Array.isArray(data)) {
    const newDataEntry = { ...data };

    Object.entries(dataMap).forEach(([fieldName, fieldValue]) => {
      if (fieldName in newDataEntry) {
        const dataFieldValue = newDataEntry[fieldName];
        if (typeof dataFieldValue === 'number') {
          newDataEntry[fieldName] = fieldValue;
        }
      }
    });

    return newDataEntry;
  }
});
